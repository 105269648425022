// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "_theme/_assets/sass/style.react.scss";

// Default Layout themes
@import "_theme/_assets/sass/themes/layout/header/base/light.scss";
@import "_theme/_assets/sass/themes/layout/header/menu/light.scss";
@import "_theme/_assets/sass/themes/layout/brand/dark.scss";
@import "_theme/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_theme/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_theme/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_theme/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_theme/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "_theme/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "_theme/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_theme/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_theme/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
  box-shadow: -1px 0 0 0.1px #ebedf3!important;
}

.react-bootstrap-table {
  thead{
    text-align: center;
  }
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-endAdornment {
  top: calc(50% - 13px) !important;
}

.table-hover {
  tbody {
    tr {
      td {
        background-color: #fff!important;
      }
      &:hover {
        td {
          background-color: #f5f5f5!important;
        }
      }
    }
  }
  thead {
    tr {
      th {
        background-color: #fff;
      }
    }
  }
}

//.theme--light {
.react-bootstrap-table {
  table {
    tbody {
      tr {
        td {
          background-color: #fff!important;
        }
        &:hover {
          td {
            background-color: #f5f5f5!important;
          }
        }
      }
    }
    thead {
      tr {
        th {
          background-color: #fff;
        }
      }
    }
  }
}

.react-bootstrap-table {
  //border-left: 0.5px solid rgba(235, 237, 243, 1);
  //border-right: 0.5px solid rgba(235, 237, 243, 1);
  table {
    margin: 0!important;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.w-full {
  width: 100%;
}

.card-action-center {
  align-self: center !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  margin-right: 0 !important;
}

.card-filter {
  min-width: 17rem;
}

.color-white {
  color: #fff !important;
}

.pointer {
  cursor: pointer;
}

.nowrap {
  white-space: nowrap!important;
}

.button-filter {
  font-weight: 600 !important;
  color: #b1b1b1 !important;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 13px !important;
}

.button-sm {
  min-width: 38px!important;
}

.min-w-5rem {
  min-width: 5rem!important;
}

.row-filter-content {
  align-items: start;
  background-color: #e8f0fe;
  padding: 8px;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.line-height-initial {
  line-height: initial;
}

.height-2-5 {
  height: 2.5rem;
}

.height-2 {
  height: 2rem;
}

.mb-0-5 {
  margin-bottom: 2px;
}

.button-custom {
  min-width: 42px;
  font-weight: 500 !important;
  color: #757575 !important;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 0.7rem !important;
  cursor: pointer;

  .fas,
  svg,
  .material-icons {
    color: #757575;
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.table.table-head-custom thead tr, .table.table-head-custom thead th {
  font-weight: 500!important;
  color: #93939e !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.MuiTooltip-tooltip {
  background: rgba(97,97,97,.9) !important;
  color: #fff;
  border-radius: 4px;
  font-size: 12px!important;
  line-height: 22px;
  display: inline-block!important;
  padding: 5px 16px;
  width: auto;
  opacity: 1;
  pointer-events: none;
}

.max-w-full {
  max-width: 100%;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-w-500 {
  max-width: 500px;
}

.max-w-800 {
  max-width: 800px;
}

.max-h-500 {
  max-height: 500px !important;
}

.max-h-350 {
  max-height: 350px !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.w-16 {
  width: 4rem;
}

.w-24 {
  width: 6rem;
}
@include media-breakpoint-up(md) {
  .candidate-statistics {
    &:not(:last-of-type) {
      border-right: 1px solid $gray-100;
    }
  }
}

@include media-breakpoint-down(sm) {
  .mt-sm-2 {
    margin-top: 8px!important;
  }
}

@include media-breakpoint-up(md) {
  .mt-md-auto {
    margin-top: auto!important;
  }
}

.MuiAutocomplete-endAdornment {
  margin-top: 1px;
}

.no-border {
  border: none!important;
}

.fake-form-input {
  font-size: 1rem!important;
  height: calc(1.5em + 1.3rem + 2px) !important;
  padding: 0.65rem 1rem!important;
  box-sizing: inherit!important;
}

.h-auto {
  height: auto!important;
}

.min-w-300 {
  min-width: 300px;
}

.min-w-230 {
  min-width: 230px;
}

.fc-button-group {
  .fc--button {
    //display: none;
  }
}

.notification-mark {
  position: absolute;
  top: 0.15rem;
  border-radius: 1rem;
  padding: 0.1rem 0.3rem !important;
  font-size: 12px !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal !important;
  height: auto;
  margin: 0 !important;
  left: 1.7rem;
  border: 2px solid #fff !important;
}

#kt_header {
  .tab-content {
    padding-right: 0!important;
    padding-left: 0!important;
  }
}

.modal-footer {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 1rem;
  z-index: 1;
}

.icon-active {
  svg {
    g [fill]{
      fill: #3699FF;
    }
  }
}

.md-field-line {
  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    label {
      width: 30%;
      max-width: 13rem;
    }

    .select-wrapper {
      width: 70%;
      max-width: 27rem;
    }

    .invalid-feedback {
      width: 100%;
    }
  }
}

.input-fake {
  position: relative;
  height: 21px;
  input {
    position: absolute;
    width: 100%;
    height: 21px;
    padding: 0 0.4rem;
    border-radius: 0.3rem;
    left: 0;
    top: 0;
  }

  .width-machine {
    padding: 0 0.4rem;
    border: 1px solid transparent;
  }
}

.nav-item {
  position: relative;

  &[data-rb-event-key="add"] {
    &:focus {
      border-color: transparent!important;
    }

    &:hover {
      border-color: #EBEDF3 #EBEDF3 #E4E6EF!important;
    }
  }

  &[data-rb-event-key="append"] {
    padding: 0!important;
    margin-left: auto!important;
    &:focus, &:hover {
      border-color: transparent!important;
    }
  }
}

.hover-tab-input {
  padding: 0 0.4rem;
  border: 1px solid transparent;
  border-radius: 0.3rem;
  &:hover {
    cursor: pointer;
  }
}
.data-table-paging {
  @include media-breakpoint-down(sm) {
    .mobile-sort {
      border-bottom: solid 1px rgb(230, 230, 230)!important;

      .MuiInputLabel-root {
        font-size: 16px;
      }

      .MuiFormControl-marginNormal {
        margin-top: 8px;
      }
    }


    tr {
      display: inline!important;
      display: initial!important;
    }

    td, th {
      min-width: 100% !important;
      max-width: 100% !important;
      width: 100%;
      align-items: center!important;
      display: flex!important;
      justify-content: space-between!important;
      border: none!important;
      padding: 4px 16px !important;
      line-height: 1.5;
      height: auto;
      min-height: 40px;

      &:last-of-type {
        border-bottom: solid 1px rgb(230, 230, 230)!important;
      }
    }

    table, thead, tbody {
      width: 100%;
      max-width: 100%;
      display: block;
    }

    thead {
      border-top: solid 1px rgb(230, 230, 230)!important;
    }

    .react-bootstrap-table {
      width: 100%;
      overflow: hidden!important;
    }
  }
}

.v-data-table__mobile-row__header {
  padding-right: 16px;
  font-weight: 600;
  min-width: 30%;
}

.v-data-table__mobile-row__cell {
  text-align: right;
  word-break: break-all;
}

.w-50 {
  width: 50% !important;
}

input {
  &.input-hidden-arrow {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: none!important;
  content: "";
  background-color: rgba(0,0,0,0);
}

.white-space-pre-wrap pre {
  white-space: pre-wrap!important;
}

.table-sticky-header {
  thead {
    position: sticky;
    top: -1px;
    z-index: 4;
    box-shadow: 0 1px 0 0 #ebedf3;

    th {
      box-shadow: inset 0 1px 0 0 #ebedf3;
    }
  }
}

.table-sticky-local {
  thead, tbody {
    tr {
      td:nth-child(1), th:nth-child(1) {
        position: sticky;
        left: -1px;
        z-index: 1;
        background-color: #fff;
        min-width: 60px;
        max-width: 60px;
        box-shadow: inset -1px 0px 0px 0px #ebedf3;
      }

      td:nth-child(2), th:nth-child(2) {
        position: sticky;
        left: 58px;
        z-index: 1;
        background-color: #fff;
        min-width: 170px;
        max-width: 170px;
        box-shadow: inset -1px 0px 0px 0px #ebedf3;
      }
    }
  }
}

.is-invalid-select {
  ~ .invalid-feedback {
    display: block!important;
  }
}

//Fix color on schedule
.fc-event-main {
  color: black !important;
}

// .nav-link:focus {
//     outline: none;
// }
