//
// Main Styles
//


////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 1: Initialize
@import "init";

// 2: Bootstrap/Material framework includes
@import "~bootstrap/scss/bootstrap";
@import "vendors/material-ui/general";

// 3: Components
@import
    "components/utilities/background",
    "components/utilities/flex",
    "components/utilities/opacity",
    "components/utilities/borders",
    "components/utilities/sizing",
    "components/utilities/position",
    "components/utilities/text",
    "components/utilities/icon",
    "components/burger-icon",
    "components/card",
    "components/wizard",
    "components/type",
    "components/breadcrumb",
    "components/nav",
    "components/alert",
    "components/buttons",
    "components/code",
    "components/dropdown",
    "components/forms",
    "components/grid",
    "components/modal",
    "components/progress",
    "components/table",
    "components/popover",
    "components/tooltip",
    "components/accordion",
    "components/datatable",
    "components/bar-chart",
    "components/checkbox",
    "components/radio",
    "components/switch",
    "components/option",
    "components/dialog",
    "components/strikethrough",
    "components/separator",
    "components/image-input",
    "components/label",
    "components/loader",
    "components/symbol",
    "components/pulse",
    "components/page-loader",
    "components/quick-search",
    "components/scrolltop",
    "components/spinner",
    "components/sticky-toolbar",
    "components/svg-icon",
    "components/svg",
    "components/timeline",
    "components/timeline-2",
    "components/timeline-3",
    "components/timeline-4",
    "components/timeline-5",
    "components/overlay",
    "components/demo",
    "components/wave",
    "components/list",
    "components/bullet",
    "components/navi",
    "components/ribbon",
    "components/editor";

// 4: Plugins
@import
    "vendors/plugins/formvalidation",
    "vendors/plugins/dropzone",
    "vendors/plugins/perfect-scrollbar",
    "vendors/plugins/prismjs",
    "vendors/plugins/fullcalendar",
    "vendors/plugins/apexcharts";

// 5: Layout
@import "layout/init";

// 6: Customizations
//@import "style.react.custom.scss";
////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY ABOVE INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

@include media-breakpoint-down(sm) {
    .max-sm-w-100 {
        width: 100% !important;
    }
}

@include media-breakpoint-up(lg) {
    .v-data-table-with-action
    > table
    > tbody
    > tr
    > td:last-child,
    .v-data-table-with-action
    > table
    > thead
    > tr
    > th:last-child {
        &:last-of-type {
            position: sticky !important;
            position: -webkit-sticky !important;
            right: -2px;
            z-index: 1;
            border-right: none!important;
            -webkit-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
            -moz-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
            box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
        }
    }

    th {
        white-space: nowrap!important;
    }

    table {
        th {
            white-space: pre-line;
        }
    }

    .v-data-table-fixed-left:not(.v-data-table-select-row) table, table.v-data-table-fixed-left:not(.v-data-table-select-row) {
        tbody {
            tr {
                td:first-child {
                    position: sticky !important;
                    position: -webkit-sticky !important;
                    left: -1px;
                    z-index: 1;
                    border-left: none!important;
                    -webkit-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    -moz-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                }
                td:nth-child(2) {
                    position: sticky !important;
                    position: -webkit-sticky !important;
                    left: 40px;
                    z-index: 1;
                    -webkit-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    -moz-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                }
            }
        }
        thead {
            tr {
                th:first-child {
                    position: sticky !important;
                    position: -webkit-sticky !important;
                    left: -1px;
                    z-index: 1;
                    border-left: none!important;
                    -webkit-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    -moz-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                }
                th:nth-child(2) {
                    position: sticky !important;
                    position: -webkit-sticky !important;
                    left: 40px;
                    z-index: 1;
                    -webkit-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    -moz-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                }
            }
        }
    }

    .v-data-table-fixed-left.v-data-table-select-row table, table.v-data-table-fixed-left.v-data-table-select-row {
        tbody {
            tr {
                td:first-child {
                    position: sticky !important;
                    position: -webkit-sticky !important;
                    left: -1px;
                    width: 40px;
                    z-index: 1;
                    border-left: none!important;
                    -webkit-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    -moz-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                }
                td:nth-child(2) {
                    position: sticky !important;
                    position: -webkit-sticky !important;
                    left: 37px;
                    z-index: 1;
                    -webkit-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    -moz-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                }

                td:nth-child(3) {
                    position: sticky !important;
                    position: -webkit-sticky !important;
                    left: 76px;
                    z-index: 1;
                    -webkit-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    -moz-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                }
            }
        }
        thead {
            tr {
                th:first-child {
                    position: sticky !important;
                    position: -webkit-sticky !important;
                    left: -1px;
                    z-index: 1;
                    border-left: none!important;
                    -webkit-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    -moz-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                }
                th:nth-child(2) {
                    position: sticky !important;
                    position: -webkit-sticky !important;
                    left: 37px;
                    z-index: 1;
                    -webkit-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    -moz-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                }
                th:nth-child(3) {
                    position: sticky !important;
                    position: -webkit-sticky !important;
                    left: 76px;
                    z-index: 1;
                    -webkit-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    -moz-box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                    box-shadow: inset 1px 0px 0px 0.1px rgba(235, 237, 243, 1)!important;
                }
            }
        }
    }
}
