.full-screen-wrapper {
  &.full-screen {
    position: fixed;
    background-color: #fff;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow-y: auto;
    z-index: 99;
  }
}
